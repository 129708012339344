@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/3d27c796fb7b5f10-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/31bf01f1e3df9060-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/81abfd76e149f91f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/66d8835ea79291bb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/cd43841a8c85a457-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/525d960de0802dcb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/2f4989ae950c371e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/1103a090ebc8d784-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: 'Breatrice';
src: url(/_next/static/media/e89f09d1970576de-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}@font-face {font-family: 'Breatrice Fallback';src: local("Arial");ascent-override: 89.56%;descent-override: 35.76%;line-gap-override: 0.00%;size-adjust: 118.58%
}.__className_27eafb {font-family: 'Breatrice', 'Breatrice Fallback'
}.__variable_27eafb {--font-beatrice: 'Breatrice', 'Breatrice Fallback'
}

